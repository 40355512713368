<!--标签维护-->
<template>
  <div class="m-grid m-grid-page p-task-config">
    <section class="m-grid-search">
      <el-input
        class="m-grid-input"
        v-model="searchData.taskName"
        placeholder="搜索任务名称"
                clearable
      ></el-input>
      <znl-input
              border
              width="150px"
              type="text"
              clearable
              :select-options="taskTypeList"
              placeholder="搜索任务类型"
              form-type="select"
              v-model="searchData.taskType"
              class="m-margin--right-10"
            >
            </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :selection="false"
        headerHeight="35"
        type="base"
      >
      </grid>
    </section>

    <task-config-edit-view
      ref="editView"
      @onEditConfig="onEditConfig"
    ></task-config-edit-view>
  </div>
</template>

<script>
import {columns} from "./modules/taskConfig/config";
import TaskConfigEditView from "./modules/taskConfig/TaskConfigEditView";
import {searchDataMap,} from "./modules/taskConfig/dataMap";
import {editTaskConfig, geTaskTypes, getTaskConfigList} from "@/api/config";

const taskTypeMap = function (value) {
  let ret = ''
  switch (value) {
    case 1:
      ret = '新增'
      break;
    case 2:
      ret = '修改'
      break;
    case 3:
      ret = '跟进'
      break;
    case 4:
      ret = '购买'
      break;
    case 5:
      ret = '其他'
      break;
  }
  return  ret
}
export default {
  name: "configTaskConfig",
  components: {
    TaskConfigEditView,
  },
  data() {
    return {
      columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        taskName: "",
        taskType: "",
      },
      isEdit: false,
      taskTypeList:[]
    };
  },
  methods: {
    async onSearch() {
      const { searchData } = this;
      let sendData = {};
      Object.entries(searchDataMap).forEach(([key, value]) => {
        sendData[value] = searchData[key];
      });
      const data = await getTaskConfigList(sendData); // 请求接口
      this.itemSource = data.Items;

    },

    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons(),
        };
      }
      this.$refs.list.changeColumns(columns);
    },

    setButtons() {
      let self = this;
      let buttons = [];
      const editBtn = {
        text: "编辑",
        click(params) {
          self.onOpenEdit(params.data);
        },
      };
      if(this.can('config_task_config:edit')) {
        buttons.push(editBtn);
      }

      return buttons;
    },

    onOpenEdit(data) {
      this.isEdit = true;
      // 对象map
      const newData = {
        id: data.TaskID,
        taskName: data.TaskName,
        taskType: data.TaskType,
        taskRemark: data.TaskDescription,
        taskScore: data.Score,
        isUse: data.IsActive,
        taskTypeName: taskTypeMap(data.TaskType)
      }
      this.$refs.editView.onOpen(newData);
    },

    async onEditConfig(data) {
      const { isEdit } = this;
      const params = {
        CFG_TaskList: [data],
      };
      let message = "";
      if (isEdit) {
        await editTaskConfig(params);
        message = "任务编辑成功";
      }
      this.$message.success(message);
      await this.onSearch(this.pagination.page);
    },
    //任务类型数据源
   async taskTypes(){
      let data=await geTaskTypes();
      this.taskTypeList=this.setOption(data)
    },
    setOption(data){
      let option = data.map((item) => {
        return{
          key:item.ItemValue,
          value:item.ItemName
        }
      })
      return option;
    },
  },
  created() {
    console.log('任务配置页面开始加载')
    this.taskTypes();
    this.onSearch();
  },
  mounted() {
    this.initColumns();
  },
  watch: {},
};
</script>

