// 标签列头配置
const columns = [
  {
    field: 'rowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c',
	  valueFormatter: ({ node: { rowIndex } }) => {
		  return rowIndex + 1;
	  }
  },

  {
    field: 'TaskName',
    headerName: '任务名称',
    editable: false,
    width: 100,
    cellClass: 't-l'
  },
  {
    field: 'TaskType',
    headerName: '任务类型',
    editable: false,
    width: 80,
    cellClass: 't-c',
	  valueFormatter({ value }) {
    	let ret = ''
		  switch (value) {
			  case 1:
			  	ret = '新增'
			  	break;
			  case 2:
			  	ret = '修改'
				  break;
			  case 3:
				  ret = '跟进'
				  break;
			  case 4:
				  ret = '购买'
				  break;
			  case 5:
				  ret = '其他'
				  break;
		  }
		  return  ret
	  }
  },
  {
    field: 'TaskDescription',
    headerName: '任务说明',
    editable: false,
    cellClass: 't-l'
  },
  {
    field: 'Score',
    headerName: '得分',
    editable: false,
    width: 80,
    cellClass: 't-c'
  },
  {
    field: 'IsActive',
    headerName: '是否启用',
    editable: false,
    width: 80,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      return value === 2 ? '是' : '否'
    }
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]

const isUseOptions = [
  {key: 2, value: '是'},
  {key: 1, value: '否'}
]

export {
  columns,
  isUseOptions
}
