const listDataMap = {
  "id": 'TaskID',
  'taskName': 'TaskName',
  'taskType': 'TaskType',
  'taskRemark': 'TaskDescription',
  'taskScore': 'Score',
  'isUse': 'IsActive',
  'taskTypeName':'TaskTypeName'
  
}

const editItemMap = {
  "id": 'TaskID',
  'taskName': 'TaskName',
  'taskType': 'TaskType',
  'taskRemark': 'TaskDescription',
  'taskScore': 'Score',
  'isUse': 'IsActive',
  'taskTypeName':'TaskTypeName'
}

const searchDataMap = {
  taskName: 'TaskName',
  taskType: 'TaskType'
}

const dateNameArr = [
  'createDate',
  'updateDate',
]


export {
  dateNameArr,
  listDataMap,
  editItemMap,
  searchDataMap
}
