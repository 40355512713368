<template>
  <div class="c-task-config-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">
      <section>
        <ul class="m-ul-box">
          <li>
            <span class="m-ul-box_title">任务名称: </span>
            <span class="m-ul-box_ctn">{{editItem.taskName}}</span>
          </li>
          <li>
            <span class="m-ul-box_title">任务类型: </span>
            <span class="m-ul-box_ctn">{{editItem.taskTypeName}}</span>
          </li>
          <li>
            <span class="m-ul-box_title">任务说明: </span>
            <span class="m-ul-box_ctn">{{editItem.taskRemark}}</span>
          </li>
          <li>
            <span class="m-ul-box_title">任务得分(额度任务按照完成度百分比得分):</span>
            <znl-input border width="90" v-model="editItem.taskScore"></znl-input>
          </li>
          <li>
            <span>是否启用: </span>
            <znl-input
              :select-options="isUseOptions"
              border
              form-type="select"
              v-model="editItem.isUse"
              width="180">
            </znl-input>
          </li>
        </ul>
      </section>
      <section class="m-margin--top-10 m-dialog-footer">
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from '@/directive/el-drag-dialog'
import {isUseOptions} from "./config"; // base on element-ui
import {editItemMap} from './dataMap'

export default {
  name: 'TaskConfigEditView',
  directives: {
    elDragDialog
  },
  data() {
    return {
      visible: false,
      title: '编辑任务',
      editItem: {
        id: 0,
        taskName: '',
        taskType: '',
        taskRemark: '',
        taskScore: '',
        isUse: '',
        taskTypeName:''
      },
      isUseOptions: isUseOptions
    }
  },
  methods: {
    onOpen(data) {
      //console.log(data)
      this.isEdit = data !== null
      this.title = data !== null ? '编辑任务' : '新增任务'
      if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem)
      } else {
        Object.keys(this.editItem).forEach(key => {
          this.editItem[key] = data[key]
        })
      }
      this.visible = true
    },

    onSave() {
      let params = this.setDataMap(this.editItem, editItemMap, 'save')
      this.$emit('onEditConfig', params)
      this.visible = false
    },
    handleDrag() {

    }
  },
  created() {

  }
}
</script>
<style lang="scss">
.c-task-config-view-dialog {
  .el-dialog {
    min-width: 600px;
  }
}

</style>
